<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')"  id="api-notifications" no-body>

    <div class="page-header">
      <b-row>
        <b-col md="12">
          <div class="filters">

            <div class="filter-column">
              <v-select
                  @input="getNotifications"
                  placeholder="Վճարման կարգավիճակ"
                  id="vue-select-paid-status"
                  :reduce="prefix => prefix.id"
                  v-model="filters.paid"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[{label: 'Հաստատված է', id: true},{label: 'Մերժված է', id: false}]"
                  class="selectorFilterApi"
              />
            </div>
            <div class="filter-column">
              <b-form-input
                  @change="getNotifications"
                  v-model="filters.CarRegNo"
                  autocomplete="off"
                  id="owner_name"
                  type="text"
                  placeholder="Համարանիշ"
                  class="w-full text-uppercase opinionFilter"
                  clearable
              />
            </div>
            <div class="filter-column">
              <b-form-input
                  @change="getNotifications"
                  v-model="filters.phone"
                  autocomplete="off"
                  id="owner_phone"
                  type="text"
                  placeholder="Հեռախոսահամար"
                  class="w-full text-uppercase opinionFilter"
                  clearable
              />
            </div>
            <div class="filter-column">
              <b-form-input
                  @change="getNotifications"
                  v-model="filters.email"
                  autocomplete="off"
                  id="owner_email"
                  type="text"
                  placeholder="էլ. փոստ"
                  class="w-full text-uppercase opinionFilter"
                  clearable
              />
            </div>

          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="items"
        :fields="fields"
    >
      <template #cell(ValidDate)="data">
        {{data.value | formatDate}}
      </template>

      <template #cell(createdAt)="data">
        {{data.value | formatDate}}
      </template>

      <template #cell(notifyDate)="data">
        {{data.value | formatDate}}
      </template>

      <template #cell(paid)="data">
        <fa-icon  icon="check"
                 :class="'table-action-icon item-status status-' + data.item.paid"/>
      </template>

      <template #cell(notificationSent)="data">
        <fa-icon  icon="check"
                 :class="'table-action-icon item-status status-' + data.item.notificationSent"/>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
          :label="'Գտնվել է ' + pagination.total + ' տող'"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
      >
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
            @input="getNotifications"
            v-model="pagination.current_page"
            :total-rows="pagination.total"
            :per-page="pagination.count"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import moduleApi from '@/store/api/moduleApi'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  name: 'api-payments',
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    vSelect
  },
  data() {
    return {
      API_STORE_MODULE_NAME: 'apiModule',
      perPage: 20,
      pageOptions: [this.perPage],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'email',
          label: 'Էլ․ փոստ',
        },
        {
          key: 'phone',
          label: 'Հեռախոսահամար',
        },
        {
          key: 'CarRegNo',
          label: 'Հաշվառման համարանիշ '
        },
        {
          key: 'paymentMethod',
          label: 'Վճարման տարբերակ '
        },
        {
          key: 'createdAt',
          label: 'Բաժանորդագրվել է'
        },
        {
          key: 'ValidDate',
          label: 'Ակտիվ է մինչև',
        },
        {
          key: 'notifyDate',
          label: 'Ծանուցման օր'
        },
        {
          key: 'paid',
          label: 'Վճարման կարգավիչակ'
        },
        {
          key: 'notificationSent',
          label: 'Ծանուցվել է'
        }
      ],
      addForm: false,
      editForm: false,
      formFields: {
        name: '',
        county: ''
      },
      form: {},
      filters: {
        CarRegNo: null,
        email: null,
        phone: null,
        paid: null
      }
    }
  },
  computed: {

    items() {
      return this.$store.state[this.API_STORE_MODULE_NAME].payments
    },

    pagination() {
      return this.$store.state[this.API_STORE_MODULE_NAME].pagination
    },

    totalRows() {
      return this.items.length
    },
  },
  mounted() {
    // Set the initial number of items

  },
  async created() {
    if (!store.hasModule(this.API_STORE_MODULE_NAME)) store.registerModule(this.API_STORE_MODULE_NAME, moduleApi)
    this.getNotifications()
  },
  methods: {
    getNotifications() {
      const filterData = {
        ...this.filters,
        current_page: this.pagination.current_page
      }
      this.$vs.loading()
      this.$store.dispatch(`${this.API_STORE_MODULE_NAME}/getPayments`, this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },
    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#api-notifications {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 15rem);
    min-height: calc(var(--vh, 1vh) * 100 - 15rem);
  }
}
#vue-select-paid-status {
  width: 230px;
}
</style>

